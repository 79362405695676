import React from 'react'
import { graphql } from 'gatsby'
import _ from 'lodash'
import { getCmsContentType } from '../services/util'

import MainLayout from '../components/MainLayout'
import Press from '../components/Press'
import DemoFooter from '../components/DemoFooter'
// import { EGAnalytics, analyticEvents } from '../services/analytics'

export default (props) => {
  const headerData = getCmsContentType(props, process.env.GATSBY_CONTENTFUL_ID_HOME)
  const pressPages = _.get(props, 'data.allContentfulPressArticle.nodes')
  const footerData = _.get(props, 'data.allContentfulHomev4.nodes[0].bottomCta')
  // useEffect(() => {
  //   EGAnalytics.track(analyticEvents.ABOUT_US_VIEWED)
  // }, [])

  return (
    <MainLayout data={headerData }>
      <Press data={{ pressPages }} />
      <DemoFooter data={ footerData }/>
    </MainLayout>
  )
}


export const pageQuery = graphql`
  query allPressPage {
    allContentfulHomeV3 {
      edges {
        node {
          title
          contentful_id
          hero {
            cta
          }
        }
      }
    }

    allContentfulPressArticle {
      nodes {
        title
        hideContents
        urlSlug
        image {
          file {
            url
          }
        }
        source
        sourceLink
        sourceLogo {
          file {
            url
          }
        }
      }
    }

    allContentfulHomev4 {
      nodes {
        bottomCta {
          title
          description {
            description
          }
          cta
          image {
            file {
              url
            }
          }
        }
      }
    }

  }
`
