import React from 'react'
import PropTypes from 'prop-types'
// import get from 'lodash/get'

import PressCard from '../PressCard'
import './styles.scss'

const PressPage = props => {
  const { data = {}} = props

  return (
    <div className='zsf-press-page'>
      <div className='title'>
        ZeroStorefront in the Press
      </div>
      <div className='subtitle'>
        Read more about ZeroStorefront 
      </div>
      <div className='press-items'>
        {data.pressPages.map((item, index) => <PressCard data={item} key={index}/>)}
      </div>
    </div>
  )
}

PressPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PressPage
